import React from 'react'

const HeaderLogo = (props) =>{
  const { fillColor } = props;
  return (
    <svg
      viewBox='0 0 717 128'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      preserveAspectRatio='xMidYMid'
      width='717'
      height='128'
    >
      <path fill={fillColor} d="M0 1.74997V26.3933H31.1045V119.168H58.5228V26.3933H89.6273V1.74997H0Z" />
      <path fill={fillColor} d="M318.915 1.74994L299.076 73.8577H298.744L280.893 1.74994H251.777L233.595 73.8577H233.263L213.424 1.74994H183.977L220.714 119.168H245.978L266.48 41.9249H266.646L286.36 119.168H311.625L348.362 1.74994H318.915Z" />
      <path fill={fillColor} d="M382.118 35.1324H382.449L395.33 74.6446H369.361L382.118 35.1324ZM369.071 1.74994L326.08 119.168H355.031L361.823 98.1697H402.992L409.95 119.168H438.901L395.537 1.74994H369.071Z" />
      <path fill={fillColor} d="M425.108 1.74994L468.431 119.168H493.116L449.752 1.74994H425.108Z" />
      <path fill={fillColor} d="M159.705 82.5968C159.705 75.3073 154.238 70.213 142.352 70.213H126.489V95.022H142.352C152.582 95.022 159.705 90.8803 159.705 82.5968ZM126.489 48.5516H139.535C147.819 48.5516 154.404 44.7412 154.404 37.3275C154.404 30.7007 149.6 26.1034 138.541 26.1034H126.489V48.5516ZM188.491 86.4072C188.491 105.252 176.107 119.168 150.966 119.168H99.3603V1.74994H143.677C169.811 1.74994 182.361 12.3528 182.361 30.3694C182.361 40.4752 178.053 51.0367 165.338 56.3381V56.6695C181.201 60.9354 188.491 71.8697 188.491 86.4072Z" />
      <path fill={fillColor} d="M530.765 6.92715H522.688V1.74997H544.64V6.92715H536.522V31.0321H530.765V6.92715Z" />
      <path fill={fillColor} d="M566.301 18.9386V31.0325H560.916V20.3054C560.916 16.5778 559.55 15.4181 556.982 15.4181C554.083 15.4181 552.26 17.2405 552.26 20.8024V31.0325H546.876V0.673492H552.26V13.3472H552.343C554 11.4834 556.36 10.5723 558.887 10.5723C563.194 10.5723 566.301 12.8088 566.301 18.9386Z" />
      <path fill={fillColor} d="M574.46 18.8135H583.323C582.785 16.7012 581.335 15.293 578.974 15.293C576.779 15.293 575.081 16.4941 574.46 18.8135ZM588.542 22.7067H574.419C575.081 25.1089 576.986 26.6 579.554 26.6C581.708 26.6414 583.282 25.6888 584.359 24.322L587.879 27.594C586.14 29.7891 583.24 31.4044 579.182 31.4044C573.3 31.4044 568.827 27.2626 568.827 20.9672C568.827 14.8788 573.093 10.6128 578.809 10.6128C584.69 10.6128 588.666 14.9202 588.666 20.8015C588.666 21.4228 588.666 22.3754 588.542 22.7067Z" />
      <path fill={fillColor} d="M522.688 104.59C522.688 96.2234 528.031 89.5137 537.599 89.5137C541.658 89.5137 546.048 91.2118 549.278 95.1051L545.012 98.7498C543.273 96.7204 540.622 95.0223 537.599 95.0223C532.546 95.0223 528.735 98.7498 528.735 104.59C528.735 110.015 532.38 113.95 537.599 113.95C540.664 113.95 543.273 112.376 545.012 110.222L549.278 113.909C546.752 117.056 542.817 119.417 537.599 119.417C529.605 119.459 522.688 114.24 522.688 104.59Z" />
      <path fill={fillColor} d="M566.549 109.104C566.549 105.791 564.313 103.554 561.331 103.554C558.183 103.554 556.112 105.956 556.112 109.104C556.112 112.252 558.307 114.654 561.331 114.654C564.23 114.654 566.549 112.335 566.549 109.104ZM550.645 109.104C550.645 103.099 554.952 98.7083 561.331 98.7083C567.585 98.7083 572.058 102.933 572.058 109.104C572.058 115.275 567.585 119.5 561.331 119.5C554.911 119.5 550.645 115.11 550.645 109.104Z" />
      <path fill={fillColor} d="M606.475 107.447V119.169H601.091V108.773C601.091 105.542 600.097 103.554 597.571 103.554C595.293 103.554 593.429 105.501 593.429 109.104V119.169H588.045V108.773C588.045 105.542 587.009 103.554 584.483 103.554C582.039 103.554 580.217 105.501 580.217 109.104V119.169H574.833V99.0397H580.217V101.732H580.258C581.832 99.7437 584.027 98.7083 586.264 98.7083C588.914 98.7083 590.985 99.7023 592.228 101.939H592.311C593.967 99.8266 596.452 98.7083 599.31 98.7083C603.742 98.7083 606.475 101.318 606.475 107.447Z" />
      <path fill={fillColor} d="M625.817 109.187C625.817 105.708 623.539 103.554 620.681 103.554C617.575 103.554 615.463 105.998 615.463 109.187C615.463 112.5 617.782 114.654 620.681 114.654C623.705 114.654 625.817 112.417 625.817 109.187ZM631.326 109.146C631.326 115.358 627.267 119.5 621.675 119.5C619.108 119.5 617.161 118.547 615.753 116.891H615.67V127.328H610.286V99.0397H615.67V101.359H615.753C617.285 99.6195 619.397 98.7083 621.841 98.7083C627.225 98.7083 631.326 103.016 631.326 109.146Z" />
      <path fill={fillColor} d="M648.763 109.187C648.763 105.956 646.609 103.554 643.71 103.554C640.811 103.554 638.533 105.749 638.533 109.187C638.533 112.459 640.687 114.654 643.669 114.654C646.444 114.654 648.763 112.459 648.763 109.187ZM653.899 99.0397V119.169H648.805V116.725H648.68C647.314 118.506 645.243 119.5 642.675 119.5C637.125 119.5 633.066 115.358 633.066 109.146C633.066 103.057 637.166 98.7083 642.551 98.7083C644.953 98.7083 647.024 99.5781 648.432 101.318H648.556V99.0397H653.899Z" />
      <path fill={fillColor} d="M676.968 107.199V119.169H671.583V108.566C671.583 104.714 670.217 103.554 667.69 103.554C664.957 103.554 663.134 105.459 663.134 109.021V119.169H657.75V99.0397H663.134V101.649H663.217C664.874 99.7023 667.193 98.7083 669.637 98.7083C673.986 98.7083 676.968 101.028 676.968 107.199Z" />
      <path fill={fillColor} d="M692.79 99.0398L688.192 111.465H688.151L683.181 99.0398H677.589L685.583 117.926L682.021 127.328H687.571L698.381 99.0398H692.79Z" />
      <path fill={fillColor} d="M532.132 69.9242C537.806 69.9242 541.575 65.8239 541.575 60.481C541.575 55.0139 537.764 50.9965 532.504 50.9965H528.404V69.9242H532.132ZM522.688 45.8193H532.38C541.658 45.8193 547.58 51.8248 547.58 60.481C547.58 69.0959 541.906 75.06 532.504 75.06H522.688V45.8193Z" />
      <path fill={fillColor} d="M550.976 54.9725H556.361V75.1014H550.976V54.9725ZM550.355 48.8428C550.355 47.0204 551.888 45.5294 553.71 45.5294C555.574 45.5294 557.106 47.0204 557.106 48.8428C557.106 50.6651 555.574 52.1561 553.71 52.1561C551.888 52.1147 550.355 50.6237 550.355 48.8428Z" />
      <path fill={fillColor} d="M558.763 72.4914L561.414 68.7224C563.07 70.2963 564.81 71.0832 566.964 71.0832C568.537 71.0832 569.449 70.4205 569.449 69.4265C569.449 68.4739 568.413 68.0183 565.762 67.19C562.449 66.0717 559.591 64.4979 559.591 60.646C559.591 57.0013 562.739 54.6405 566.839 54.6405C569.656 54.6405 571.892 55.386 574.17 57.167L571.561 61.143C569.987 59.652 568.206 58.9893 566.757 58.9893C565.887 58.9893 564.768 59.4449 564.768 60.5218C564.768 61.3501 565.845 61.93 567.833 62.6755C572.224 64.2908 574.75 65.2434 574.75 69.178C574.75 72.7813 572.182 75.4321 566.922 75.4321C563.733 75.4321 560.999 74.3966 558.763 72.4914Z" />
      <path fill={fillColor} d="M589.411 54.6817V60.5216C588.872 60.4802 588.293 60.4802 588.003 60.4802C584.648 60.4802 582.867 62.7581 582.867 65.6988V75.0591H577.483V54.9302H582.867V57.9123H582.908C584.441 55.7586 586.636 54.5989 588.997 54.5989C589.204 54.6403 589.328 54.6403 589.411 54.6817Z" />
      <path fill={fillColor} d="M610.742 54.972V75.1009H605.358V72.6159H605.275C603.742 74.4797 601.547 75.4323 599.311 75.4323C594.962 75.4323 591.938 73.2371 591.938 67.1074V54.972H597.281V65.6577C597.281 69.3853 598.4 70.5864 600.926 70.5864C603.287 70.5864 605.399 68.5984 605.399 64.7466V54.972H610.742Z" />
      <path fill={fillColor} d="M630.167 65.1191C630.167 61.6401 627.889 59.4863 625.031 59.4863C621.925 59.4863 619.812 61.93 619.812 65.1191C619.812 68.4325 622.132 70.5862 625.031 70.5862C628.054 70.5862 630.167 68.3497 630.167 65.1191ZM635.634 65.0777C635.634 71.2903 631.575 75.4321 625.983 75.4321C623.416 75.4321 621.469 74.4795 620.061 72.8228H619.978V83.2599H614.594V54.9718H619.978V57.2912H620.061C621.593 55.5517 623.706 54.6405 626.149 54.6405C631.575 54.6405 635.634 58.9479 635.634 65.0777Z" />
      <path fill={fillColor} d="M638.781 68.7228V59.4038H636.379V54.9308H638.781V48.3454L644.124 47.7655V54.9308H649.591V59.4038H644.124V67.9772C644.124 69.7168 644.662 70.5451 645.905 70.5451C646.567 70.5451 647.561 70.2966 648.472 69.841L649.881 74.1899C648.555 74.894 647.23 75.4324 644.538 75.4324C641.059 75.4324 638.781 73.2787 638.781 68.7228Z" />
      <path fill={fillColor} d="M652.034 54.9725H657.419V75.1014H652.034V54.9725ZM651.413 48.8428C651.413 47.0204 652.946 45.5294 654.768 45.5294C656.632 45.5294 658.164 47.0204 658.164 48.8428C658.164 50.6651 656.632 52.1561 654.768 52.1561C652.946 52.1147 651.413 50.6237 651.413 48.8428Z" />
      <path fill={fillColor} d="M676.139 65.0363C676.139 61.7229 673.903 59.4863 670.921 59.4863C667.773 59.4863 665.702 61.8885 665.702 65.0363C665.702 68.184 667.897 70.5862 670.921 70.5862C673.82 70.5862 676.139 68.2668 676.139 65.0363ZM660.194 65.0363C660.194 59.0307 664.501 54.6405 670.879 54.6405C677.133 54.6405 681.606 58.8651 681.606 65.0363C681.606 71.2075 677.133 75.4321 670.879 75.4321C664.501 75.4321 660.194 71.0418 660.194 65.0363Z" />
      <path fill={fillColor} d="M703.682 63.1311V75.1007H698.298V64.4979C698.298 60.646 696.931 59.4863 694.405 59.4863C691.671 59.4863 689.849 61.3915 689.849 64.9534V75.1007H684.464V54.9718H689.849V57.5811H689.932C691.588 55.6345 693.908 54.6405 696.351 54.6405C700.659 54.6405 703.682 56.9599 703.682 63.1311Z" />
      <path fill={fillColor} d="M709.108 51.9076H709.812C710.516 51.9076 710.889 51.5763 710.889 51.0379C710.889 50.5823 710.516 50.2095 709.853 50.2095H709.108V51.9076ZM711.22 52.9845L712.711 55.2625H711.013L709.688 53.1502H709.108V55.2625H707.658V48.8842H710.143C711.717 48.8842 712.38 49.7953 712.38 51.0379C712.421 52.0733 711.966 52.6946 711.22 52.9845ZM714.534 52.1561C714.534 49.5883 712.546 47.476 709.936 47.476C707.327 47.476 705.298 49.5883 705.298 52.1561C705.298 54.724 707.286 56.7949 709.895 56.7949C712.546 56.7949 714.534 54.6826 714.534 52.1561ZM716.232 52.1561C716.232 55.5524 713.498 58.4516 709.936 58.4516C706.457 58.4516 703.682 55.5938 703.682 52.1561C703.682 48.7599 706.457 45.8193 709.978 45.8193C713.498 45.8193 716.232 48.7185 716.232 52.1561Z" />
    </svg>
  )
}

HeaderLogo.defaultProps = {
  fillColor: 'currentColor',
};

export default HeaderLogo
