import React from 'react'

const strokeStyle = { 
  // vectorEffect: 'non-scaling-stroke' 
}

const ArrowLeft = () => (
  <svg 
  width="23" 
  height="20" 
  viewBox="0 0 23 20" 
  fill="none" 
  preserveAspectRatio='xMidYMid'
  xmlns="http://www.w3.org/2000/svg"
  >
    <path style={strokeStyle} d="M0 10.1274H21.1865" stroke="#111111" strokeWidth="2"/>
    <path style={strokeStyle} d="M13.1019 1.00012L22 10.0001L13.1019 19.0001" stroke="#111111" strokeWidth="2" strokeLinejoin="round"/>
  </svg>

)

export default ArrowLeft
