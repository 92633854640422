import React from 'react'
import Nav from './nav'
import Footer from './footer'

import '../styles/layout.css'
import '../styles/theme.css'
import styles from './layout.module.css'

const Layout = ({ children, companyInfo, siteInfo, onHideNav, onShowNav, showNav, noFooter, site}) => {
  return (
    <>
      <Nav companyInfo={companyInfo} onHideNav={onHideNav} onShowNav={onShowNav} showNav={showNav} site={site}
           siteInfo={siteInfo}/>

      <div className={`${styles.wrapper}`}>
        <div className={`${styles.content}`}>
          {children}
        </div>

        {(!noFooter) ? <Footer companyInfo={companyInfo} siteInfo={siteInfo} site={site}/> : null}
      </div>
    </>
  )
}

export default Layout
