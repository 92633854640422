import React from 'react'
import Icon from './icons'
import { spacing, responsiveParaLarge, responsiveParaSmall, responsiveParaXSmall } from '../components/typography.module.css'

import styles from './footer.module.css'

const Footer = ({companyInfo, siteInfo, site}) => {
  const footerClassName = 'footer'+site;
  return(
    <footer className={`${styles.footer} ${styles[footerClassName]}`}>
      <div className={styles.footerWrapper}>

        {/*Info bar*/}
        {
          site==="Adelaide" ?
            <div className={`${styles.infoBar} ${styles.infoBarAdelaide}`}>
          <div className={`${styles.cell} ${spacing} ${responsiveParaLarge}`}
          dangerouslySetInnerHTML={{ __html: siteInfo.footerMessage }}>
          </div>
              <div className={`${styles.cell} ${spacing}`}>
                <h4 className={responsiveParaXSmall}>Office</h4>
                <span className={responsiveParaSmall}
                      dangerouslySetInnerHTML={{ __html: companyInfo.address }}>
            </span>
              </div>
              <div className={`${styles.cell} ${spacing}`}>
                <h4 className={responsiveParaXSmall}>New Business</h4>
                <a href={"mailto:"+companyInfo.nb_email} className={responsiveParaSmall}>{companyInfo.nb_email}</a>
                <br/>
                <a href={"tel:"+companyInfo.nb_phone} className={responsiveParaXSmall} >{companyInfo.nb_phone}</a>
              </div>
            </div>
            :
            <div className={styles.infoBar}>
            <div className={`${styles.cell} ${spacing} ${responsiveParaLarge}`}
            dangerouslySetInnerHTML={{ __html: siteInfo.footerMessage }}>
            </div>
              <div className={`${styles.cell} ${spacing}`}>
                <h4 className={responsiveParaXSmall}>Office</h4>
                <span className={responsiveParaSmall}
                      dangerouslySetInnerHTML={{ __html: companyInfo.address }}>
            </span>
              </div>
              <div className={`${styles.cell} ${spacing}`}>
                <h4 className={responsiveParaXSmall}>New Business</h4>
                <a href={"mailto:"+companyInfo.nb_email} className={responsiveParaSmall}>{companyInfo.nb_email}</a>
                <br/>
                <a href={"tel:"+companyInfo.nb_phone} className={responsiveParaXSmall} >{companyInfo.nb_phone}</a>
              </div>
              <div className={`${styles.cell} ${spacing}`}>
                <h4  className={responsiveParaXSmall} >Press Enquiries</h4>
                <a href={"mailto:"+companyInfo.pr_email} className={responsiveParaSmall}>{companyInfo.pr_email}</a>
                <br/>
                <a href={"tel:"+companyInfo.pr_phone} className={responsiveParaSmall}>{companyInfo.pr_phone}</a>
              </div>
            </div>
        }


        {/*Link bar*/}
        <div className={`${styles.linkBar} ${spacing} `}>

          <a className={`${styles.cell} hideMed`}>
            {
              site==="Adelaide" ?
              <Icon symbol='HeaderLogoWhite'/>
              :
              <Icon symbol='HeaderLogo'/>
            }

          </a>

          {companyInfo.social_linkedin && (
            <a className={`${styles.cell} ${styles.social}`}href={companyInfo.social_linkedin}>
              <span>Linkedin</span>
            <Icon symbol='arrowTopLeft'/>
          </a>
          )}
          {companyInfo.social_instagram && (
            <a className={`${styles.cell} ${styles.social}`} href={companyInfo.social_instagram}>
              <span>Instagram</span>
            <Icon symbol='arrowTopLeft'/>
          </a>
          )}

          {companyInfo.social_facebook && (
            <a className={`${styles.cell} ${styles.social}`} href={companyInfo.social_facebook}>
              <span>Facebook</span>
            <Icon symbol='arrowTopLeft'/>
          </a>
          )}

          <span className={`${styles.cell} ${styles.disclaimer} hideMed`} >
            {siteInfo.footerDisclaimer}
          </span>

        </div>

        <div className={`${styles.linkBar} hideOnLarge ${spacing}` }>
          <a className={`${styles.cell} ${styles.logo} `}>
            <Icon symbol='HeaderLogo'/>
          </a>
        <span className={`${styles.cell} ${styles.disclaimer}`} >
            {siteInfo.footerDisclaimer}
          </span>
        </div>

      </div>
    </footer>
  )
}

export default Footer
