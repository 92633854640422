import {graphql, StaticQuery} from 'gatsby'
import React, {useState} from 'react'
import Layout from '../components/layout'

function LayoutContainer(props) {
  const [showNav, setShowNav] = useState(false)

  function handleShowNav() {
    setShowNav(true)
  }

  function handleHideNav() {
    setShowNav(false)
  }

  return (
    <StaticQuery
      query={query}
      render={data => {
        if (!data.site) {
          throw new Error(
            'Missing "Site settings". Open the studio at http://localhost:3333 and add "Site settings" data'
          )
        }
        if (!data.companyInfo) {
          throw new Error(
            'Missing "Company info". Open the studio at http://localhost:3333 and add "Company info" data'
          )
        }
        return (
          // TODO
          // siteNav={data.site.title} ??
          <Layout
            {...props}
            showNav={showNav}
            companyInfo={data.companyInfo}
            siteInfo={data.site}
            onHideNav={handleHideNav}
            onShowNav={handleShowNav}
            site={data.site.officeName}
          />
        )
      }}
    />
  )
}

export default LayoutContainer


const query = graphql`
  query SiteTitleQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      footerMessage
      footerDisclaimer
      officeName
    }
    companyInfo: sanityCompanyInfo(_id: { regex: "/(drafts.|)companyInfo/" }) {
      address
      nb_email
      nb_phone
      pr_email
      pr_phone
      social_facebook
      social_linkedin
      social_instagram
    }
  }
`