import React from 'react'
import HamburgerIcon from './hamburger'
import SkullIcon from './skull'
import BackslashIcon from './backslash'
import ArrowTopLeft from './arrowTopLeft'
import ArrowLeft from './arrowLeft'
import HeaderLogo from './headerLogo'
// import HeaderLogoWhite from './headerLogoWhite'
function Icon (props) {
  switch (props.symbol) {
    case 'hamburger':
      return <HamburgerIcon />
    case 'skull':
      return <SkullIcon />
    case 'backslash':
      return <BackslashIcon />
    case 'arrowTopLeft':
      return <ArrowTopLeft />
    case 'HeaderLogo':
      return <HeaderLogo />
    case 'HeaderLogoWhite':
      return <HeaderLogo fillColor="#ffffff"/>
    case 'arrowLeft':
      return <ArrowLeft />
    default:
      return <span>Unknown icon: {props.symbol}</span>
  }
}

export default Icon
