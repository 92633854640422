import {Link} from 'gatsby'
import React from 'react'
import Icon from './icons'
import {cn} from '../lib/helpers'

import styles from './nav.module.css'
import logo_tbwa_tdc from "../assets/logos/logo_tbwa_tdc.svg"

let siteBaseUrl = 'https://dev.tbwa.com.au';
if (process.env.BRANCH==="master"){
  siteBaseUrl = 'https://tbwa.com.au';
}


const Nav = ({onHideNav, onShowNav, showNav, companyInfo, site, siteInfo}) => {
  const [activeMenu, setActiveMenu] = React.useState(site);

  const menu = () => {
    if (site === "Sydney") {
      //Sydney Menu
      return (
        <div className={cn(styles.root, styles.sydney)}>
          <div className={styles.wrapper}>
            <a className={styles.icon} href="/">
              <Icon symbol='backslash'/>
            </a>

            <button className={cn(styles.toggleNavButton, showNav && styles.opened)}
                    onClick={showNav ? onHideNav : onShowNav}>
              {/* <Icon symbol='hamburger' /> */}
              <div className={styles.hamburger}>
                <span className={styles.line}></span>
                <span className={styles.line}></span>
                <span className={styles.line}></span>
              </div>
            </button>

            <Link className={cn(styles.icon, styles.hideSmall)} to='/'>
              <Icon symbol='skull'/>
            </Link>
          </div>

          <div className={cn(showNav && styles.active, styles.backgroundClose)} onClick={showNav ? onHideNav : onShowNav}>
          </div>
          <nav className={cn(styles.nav, showNav && styles.showNav)}>
            <div className={styles.tbwaLogoTdcWrapper}>
              <img className={styles.tbwaLogoTdc} src={logo_tbwa_tdc} alt="TBWA"/>
              <div className={styles.officeToggle}>
                <div className={cn(styles.office, activeMenu === 'Sydney' && styles.officeActive)} onClick={() => {
                  {
                    setActiveMenu('Sydney')
                  }
                }}>
                 SYDNEY
                </div>
                <div className={cn(styles.office, activeMenu === 'Melbourne' && styles.officeActive)} onClick={() => {
                  {
                    setActiveMenu('Melbourne')
                  }
                }}>
                MELBOURNE
                </div>
                <div className={cn(styles.office, activeMenu === 'Adelaide' && styles.officeActive)} onClick={() => {
                  {
                    setActiveMenu('Adelaide')
                  }
                }}>
                  ADELAIDE
                </div>
              </div>
            </div>
            {menuItems()}
          </nav>
        </div>
      )
    } else if (site === "Melbourne") {
      //Melbourne Menu
      return (
        <div className={cn(styles.root, styles.melbourne)}>
          <div className={styles.wrapper}>
            <a className={styles.icon} href="/">
              <Icon symbol='backslash'/>
            </a>

            <button className={cn(styles.toggleNavButton, showNav && styles.opened)}
                    onClick={showNav ? onHideNav : onShowNav}>
              {/* <Icon symbol='hamburger' /> */}
              <div className={styles.hamburger}>
                <span className={styles.line}></span>
                <span className={styles.line}></span>
                <span className={styles.line}></span>
              </div>
            </button>

            <Link className={cn(styles.icon, styles.hideSmall)} to='/'>
              <Icon symbol='skull'/>
            </Link>
          </div>

          <div className={cn(showNav && styles.active, styles.backgroundClose)} onClick={showNav ? onHideNav : onShowNav}>
          </div>
          <nav className={cn(styles.nav, showNav && styles.showNav)}>
            <div className={styles.tbwaLogoTdcWrapper}>
              <img className={styles.tbwaLogoTdc} src={logo_tbwa_tdc} alt="TBWA"/>
              <div className={styles.officeToggle}>
                <div className={cn(styles.office, activeMenu === 'Sydney' && styles.officeActive)} onClick={() => {
                  {
                    setActiveMenu('Sydney')
                  }
                }}>
                  SYDNEY
                </div>
                <div className={cn(styles.office, activeMenu === 'Melbourne' && styles.officeActive)} onClick={() => {
                  {
                    setActiveMenu('Melbourne')
                  }
                }}>
                  MELBOURNE
                </div>
                <div className={cn(styles.office, activeMenu === 'Adelaide' && styles.officeActive)} onClick={() => {
                  {
                    setActiveMenu('Adelaide')
                  }
                }}>
                  ADELAIDE
                </div>
              </div>
            </div>
            {menuItems()}
          </nav>
        </div>
      )
    } else if (site === "Adelaide") {
      //Adelaide Menu
      return (
        <div className={cn(styles.root, styles.adelaide)}>
          <div className={styles.wrapper}>
            <a className={styles.icon} href="/">
              <Icon symbol='backslash'/>
            </a>

            <button className={cn(styles.toggleNavButton, showNav && styles.opened)}
                    onClick={showNav ? onHideNav : onShowNav}>
              {/* <Icon symbol='hamburger' /> */}
              <div className={styles.hamburger}>
                <span className={styles.line}></span>
                <span className={styles.line}></span>
                <span className={styles.line}></span>
              </div>
            </button>

            <Link className={cn(styles.icon, styles.hideSmall)} to='/'>
              <Icon symbol='skull'/>
            </Link>
          </div>

          <div className={cn(showNav && styles.active, styles.backgroundClose)} onClick={showNav ? onHideNav : onShowNav}>
          </div>
          <nav className={cn(styles.nav, showNav && styles.showNav)}>
            <div className={styles.tbwaLogoTdcWrapper}>
              <img className={styles.tbwaLogoTdc} src={logo_tbwa_tdc} alt="TBWA"/>
              <div className={styles.officeToggle}>
                {/* <div className={cn(styles.office, activeMenu === 'Sydney' && styles.officeActive)} onClick={() => {
                  {
                    setActiveMenu('Sydney')
                  }
                }}>
                  SYDNEY
                </div>
                <div className={cn(styles.office, activeMenu === 'Melbourne' && styles.officeActive)} onClick={() => {
                  {
                    setActiveMenu('Melbourne')
                  }
                }}>
                  MELBOURNE
                </div> */}
                <div className={cn(styles.office, activeMenu === 'Adelaide' && styles.officeActive)} onClick={() => {
                  {
                    setActiveMenu('Adelaide')
                  }
                }}>
                  ADELAIDE
                </div>
              </div>
            </div>
            {menuItems()}
          </nav>
        </div>
      )
    } else {
      //AU Menu
      return (
        <div className={cn(styles.root, styles.au)}>
          <div className={styles.wrapper}>
            <a className={styles.icon} href="/">
              <Icon symbol='backslash'/>
            </a>

            <button className={cn(styles.toggleNavButton, showNav && styles.opened)}
                    onClick={showNav ? onHideNav : onShowNav}>
              {/* <Icon symbol='hamburger' /> */}
              <div className={styles.hamburger}>
                <span className={styles.line}></span>
                <span className={styles.line}></span>
                <span className={styles.line}></span>
              </div>
            </button>

            <Link className={cn(styles.icon, styles.hideSmall)} to='/'>
              <Icon symbol='skull'/>
            </Link>
          </div>

          <div className={cn(showNav && styles.active, styles.backgroundClose)} onClick={showNav ? onHideNav : onShowNav}>
          </div>
          <nav className={cn(styles.nav, showNav && styles.showNav)}>
            <ul>
              <li className={styles.welcomeLogo}>
                <Icon symbol='HeaderLogo' />
              </li>
              <li>
                <a href='https://tbwasydney.com.au/'>
                  <span>Sydney</span>
                </a>
              </li>
              <li>
                <a href='/melbourne'>
                  <span>Melbourne</span>
                </a>
              </li>
              <li>
                <a href='/adelaide'>
                  <span>Adelaide</span>
                </a>
              </li>
              <li>
                <div className={`${styles.contactInfo}`}>
                  <div className={`${styles.contactItem}`}>
                    <h4>Sydney</h4>
                    <a href='mailto:sydney.reception@tbwa.com'>sydney.reception@tbwa.com</a><br/>
                    <a href='tel:0285845500'>02 8584 5500</a>
                  </div>
                  <div className={`${styles.contactItem}`}>
                    <h4>Melbourne</h4>
                    <a href='mailto:melbourne.reception@tbwa.com' >melbourne.reception@tbwa.com</a><br/>
                    <a href='tel:0396908555' >03 9690 8555</a>
                  </div>
                  <div className={`${styles.contactItem}`}>
                    <h4>Adelaide</h4>
                    <a href='mailto:adelaide.reception@tbwa.com' >adelaide.reception@tbwa.com</a><br/>
                  </div>
                </div>

              </li>
            </ul>
          </nav>
        </div>
      )
    }
  }

  const menuItems = () => {
    if (activeMenu === "Sydney") {
      //Sydney Menu
      return (<SydneyMenuItems companyInfo={companyInfo} showNav={showNav} siteBaseUrl={siteBaseUrl}/>)
    } else if (activeMenu === "Melbourne") {
      //Melbourne Menu
      return (<MelbourneMenuItems companyInfo={companyInfo} showNav={showNav} siteBaseUrl={siteBaseUrl}/>)
    } else if (activeMenu === "Adelaide") {
      //Melbourne Menu
      return (<AdelaideMenuItems companyInfo={companyInfo} showNav={showNav} siteBaseUrl={siteBaseUrl}/>)
    } else {
      return (<AuMenuItems companyInfo={companyInfo} showNav={showNav} siteBaseUrl={siteBaseUrl}/>)
    }
  }

  return (
      menu ()
  )
}

const SydneyMenuItems = ({companyInfo, showNav, siteBaseUrl}) => (
  <ul>
    <li>
      <a href='https://tbwasydney.com.au/'>
        <span>Home</span>
      </a>
    </li>
    <li>
      <a href='https://tbwasydney.com.au/about/'>
        <span>About</span>
      </a>
    </li>
    <li>
      <a href='https://tbwasydney.com.au/capabilities/'>
        <span>Capabilities</span>
      </a>
    </li>
    <li>
      <a href='https://tbwasydney.com.au/point-of-view/'>
        <span>Point of View</span>
      </a>
    </li>
    <li>
      <a href='https://tbwasydney.com.au/our-work/'>
        <span>Work</span>
      </a>
    </li>
    <li>
      <a href='https://tbwasydney.com.au/contact/'>
        <span>Contact</span>
      </a>
    </li>
    <li>
      <div className={`${styles.contactInfo}`}>
        <div className={`${styles.contactItem}`}>
          <h4>Office</h4>
          <span>
              137 Pyrmont Street, Pyrmont, Sydney, NSW 2009
            </span>
          <br/>
        </div>

        <div className={`${styles.contactItem}`}>
          <h4>New Business</h4>
          <a href={"mailto:" + companyInfo.nb_email}>{companyInfo.nb_email}</a>
          <br/>
          <a href={"tel:" + companyInfo.nb_phone}>{companyInfo.nb_phone}</a>
        </div>

        <div className={`${styles.contactItem}`}>
          <h4>Press enquiries</h4>
          <a href={"mailto:" + companyInfo.pr_email}>{companyInfo.pr_email}</a>
          <br/>
          <a href={"mailto:" + companyInfo.pr_phone}>{companyInfo.pr_phone}</a>
        </div>
      </div>
    </li>
  </ul>
)

const MelbourneMenuItems = ({companyInfo, showNav, siteBaseUrl}) => (
  <ul>
    <li>
      <a href='/melbourne'>
        <span>Home</span>
      </a>
    </li>
    <li>
      <a href='/melbourne/work'>
        <span>Work</span>
      </a>
    </li>
    <li>
      <a href='/melbourne/about'>
        <span>About</span>
      </a>
    </li>
    <li>
      <a href='/melbourne/contact'>
        <span>Contact</span>
      </a>
    </li>
    <li>
      <a href='/melbourne/careers'>
        <span>Careers</span>
      </a>
    </li>
    <li>
      <div className={`${styles.contactInfo}`}>
        <div className={`${styles.contactItem}`}>
          <h4>Office</h4>
          <span>
              288 Coventry St, South Melbourne VIC 3205
            </span>
          <br/>
        </div>

        <div className={`${styles.contactItem}`}>
          <h4>General Inquiries</h4>
          <a href="mailto:melbourne.reception@tbwa.com">melbourne.reception@tbwa.com</a>
          <br/>
          <a href="tel:0285845500">03 9690 8555</a>
          <h4>New Business Inquiries</h4>
          <a href="mailto:mel.new.business@tbwa.com">mel.new.business@tbwa.com</a>
        </div>
      </div>

    </li>
  </ul>
)

const AdelaideMenuItems = ({companyInfo, showNav}) => (
  <ul>
    <li>
      <a href='/adelaide'>
        <span>Home</span>
      </a>
    </li>
    <li>
      <a href='/adelaide/work'>
        <span>Work</span>
      </a>
    </li>
    <li>
      <a href='/adelaide/about'>
        <span>About</span>
      </a>
    </li>
    <li>
      <a href='/adelaide/contact'>
        <span>Contact</span>
      </a>
    </li>
    <li>
      <a href='/adelaide/careers'>
        <span>Careers</span>
      </a>
    </li>
    <li>
      <a href='https://tbwamelbourne.com.au/legals/' target={'_blank'}>
        <span>Legals</span>
      </a>
    </li>
    <li>
      <div className={`${styles.contactInfo}`}>
        <div className={`${styles.contactItem}`}>
          <h4>Office</h4>
          <span>
             Level 1, 275 Rundle Street, Adelaide, SA, 5000
            </span>
          <br/>
        </div>

        <div className={`${styles.contactItem}`}>
          <h4>All enquiries</h4>
          <a href="mailto:adelaide.reception@tbwa.com">adelaide.reception@tbwa.com</a>
          <br/>
          <a href="tel:0871303797 ">08 7130 3797 </a>
        </div>

        <div className={`${styles.contactItem}`}>
          <h4>New Business</h4>
          <a href={"mailto:" + companyInfo.nb_email}>{companyInfo.nb_email}</a>
          <br/>
          <a href={"tel:" + companyInfo.nb_phone}>{companyInfo.nb_phone}</a>
        </div>
      </div>

    </li>
  </ul>
)

const AuMenuItems = ({companyInfo, showNav, siteBaseUrl}) => (
    <ul>
      <li className={styles.welcomeLogo}>
        <Icon symbol='HeaderLogo' />
      </li>
      <li>
        <a href='https://tbwasydney.com.au/'>
          <span>Sydney</span>
        </a>
      </li>
      <li>
        <a href='/melbourne'>
          <span>Melbourne</span>
        </a>
      </li>
      <li>
        <a href='/adelaide'>
          <span>Adelaide</span>
        </a>
      </li>
      <li>
        <div className={`${styles.contactInfo}`}>
          <div className={`${styles.contactItem}`}>
            <h4>Sydney</h4>
            <a href='mailto:sydney.reception@tbwa.com'>sydney.reception@tbwa.com</a><br/>
            <a href='tel:0285845500'>02 8584 5500</a>
          </div>
          <div className={`${styles.contactItem}`}>
            <h4>Melbourne</h4>
            <a href='mailto:melbourne.reception@tbwa.com' >melbourne.reception@tbwa.com</a><br/>
            <a href='tel:0396908555' >03 9690 8555</a>
          </div>
          <div className={`${styles.contactItem}`}>
            <h4>Adelaide</h4>
            <a href='mailto:adelaide.reception@tbwa.com' >adelaide.reception@tbwa.com</a><br/>
          </div>
        </div>

      </li>
    </ul>
)

export default Nav
